$font-family: 'SeatBcn-Regular';

// color body
$color-body: #33302e;
$light-color-body: rgba(246,245,244,1);
$navbar-color-body: #ffffff;

//palette seat
$orangeStrong: #EA5D1A;
$orangeBase  : #EE7D05;
$orangeMedium: #ED9551;
$orangeLight : #F8B877;

// Hover effects

$hoverEffect: #F08D5E;
$hoverEffectBlack: #575757;

$textLight : #8c8c8c;
$textError: red;

$palette: (
  orange: (
    base  : $orangeBase,
    strong: $orangeStrong,
    medium: $orangeMedium,
    light : $orangeLight
  ),
  text: (
    base: $color-body,
    light: $textLight,
    error: $textError
  ));
