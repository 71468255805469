@import './styles/variables';
/* You can add global styles to this file, and also import other style files */
*{
  font-family: $font-family
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'SeatBcn';
  src: url('assets/fonts/SeatBcn-Regular.ttf') format('truetype'),
    url('assets/fonts/SeatBcn-Regular.eot'),
    url('assets/fonts/SeatBcn-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'SeatBcn';
  src: url('assets/fonts/SeatBcn-Bold.ttf') format('truetype'),
    url('assets/fonts/SeatBcn-Bold.eot'),
    url('assets/fonts/SeatBcn-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'SeatBcn-Black';
  src: url('assets/fonts/SeatBcn-Black.ttf') format('truetype'),
    url('assets/fonts/SeatBcn-Black.eot'),
    url('assets/fonts/SeatBcn-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

a {
  text-decoration: none;
}
